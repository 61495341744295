import { ComponentRef, Directive, Input, Type, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appDynamicHost]',
})
export class DynamicHostDirective {
  @Input() appDynamicHost!: Type<any>;
  componentRef!: ComponentRef<any>;

  constructor(public viewContainerRef: ViewContainerRef) {}

  createComponet(): void {
    this.componentRef = this.viewContainerRef.createComponent(this.appDynamicHost);
  }
}
