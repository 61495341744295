import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  @Input() maxHeight = '';
  @Input() minHeight = '';
  @Input() subtractHeight = 0;
  @Input() height = '100%';
  @Input() overflow = 'auto';
}
