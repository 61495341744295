import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TeamMember } from '../interfaces/team-members.interface';
import { UserService } from './user.service';

const BASE_URL = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  account: any;
  members: any[] = [];
  /**
   *
   * @param http http client to send http requests
   */
  constructor(
    private http: HttpClient, private userService: UserService
  ) {}

  getAccount() {
    return this.http.get(`${BASE_URL}/accounts/${this.account_id}`);
  }

  /**
   * getter to use globally the account_id in telemated
   */
  get account_id() {
    return localStorage.getItem('account_id') || '';
  }

  /**
   * update billing information
   * @param accountInformation new information about the account
   */
  updateBillingInformation(accountInformation: any) {
    return this.http.put(`${BASE_URL}/accounts/${this.account_id}`, accountInformation);
  }

  setTeamMembers(members: any[]) {
    this.members = members;
  }

  get teamMembers() {
    return (this.members && this.members.length > 0) ? this.members : [];
  }

  /** Get team members */
  getTeamMembers(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.getAccount().subscribe({
        next: (account: any) => {
          let teamMembers = account.users.map((member: TeamMember) => {
            return {
              name: `${member.first_name} ${member.last_name}`,
              id: member.id,
            };
          });
          teamMembers.unshift({
            name: this.userService.user_name,
            id: this.userService.user_id,
          });
          resolve(teamMembers);
        },
        error: () => {
          reject([]);
        }
      });
    });
  }
}
