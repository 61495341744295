import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { newMemberBodyReq } from '../interfaces/team-members.interface';

const BASE_URL = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /**
   *
   * @param http http client to send http requests
   */
  constructor(private http: HttpClient) {}

  getUser() {
    return this.http.get(`${BASE_URL}/users/${this.user_id}`);
  }

  updateUser(userData: any, userId: string) {
    return this.http.put(`${BASE_URL}/users/${userId}`, userData);
  }

  /**
   * getter to use user_id the account_id in telemated
   */
  get user_id() {
    return localStorage.getItem('user_id') || '';
  }

  get user_type() {
    return localStorage.getItem('user_type') || '';
  }

  get user_name() {
    return localStorage.getItem('user_name') || '';
  }

  /**
   * Add a new team member to the account
   * @param newMember new team member information
   */
  addTeamMember(newMember: newMemberBodyReq) {
    return this.http.post(`${BASE_URL}/users`, newMember);
  }

  /**
   * Update a team member status
   * @param memberId team member id
   * @param status new status
   */
  updateMemberStatus(memberId: string, status: string) {
    return this.http.patch(`${BASE_URL}/users/${memberId}`, { status });
  }
}
