<div class="loading-container">
  <div class="loading loadingFadeIn">
    <div [class]="'loading-content' + alertController.animationLoading">
      <div class="loader-container">
        <app-item>
          <span class="loader"></span>
        </app-item>
        <span *ngIf="alertController.loadingInfo.progressPercentage" class="percentage">
          {{ alertController.loadingInfo.progressPercentage }}%
        </span>
      </div>
      <app-item marginTop="40px">
        <div class="title">{{ alertController.loadingInfo.title }}</div>
      </app-item>
      <app-item marginTop="20px" *ngIf="alertController.loadingInfo.msg">
        <div class="description">{{ alertController.loadingInfo.msg }}</div>
      </app-item>
      <app-item
        marginTop="50px"
        marginBottom="10px"
        marginRight="auto"
        marginLeft="auto"
        *ngIf="alertController.loadingInfo.progressBar">
        <app-progress-bar
          [progress]="alertController.loadingInfo.progressBarValue || 33.33"
          width="100%"
          [backgroundProgress]="alertController.loadingInfo.progressBarColor || 'primary'"></app-progress-bar>
      </app-item>
    </div>
  </div>
</div>
