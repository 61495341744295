import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar.component';
import { RouterModule } from '@angular/router';
import { DropdownContainerModule } from 'src/app/shared/components/dropdown-container/dropdown-container.module';
import { MatchUrlPipe } from './pipes/match-url.pipe';

@NgModule({
  declarations: [NavbarComponent, MatchUrlPipe],
  imports: [CommonModule, RouterModule, DropdownContainerModule],
  exports: [NavbarComponent],
})
export class NavbarModule {}
