import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegalPoliciesComponent } from './legal-policies/legal-policies.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { ItemModule } from 'src/app/shared/components/item/item.module';
import { CheckboxModule } from 'src/app/shared/components/checkbox/checkbox.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [LegalPoliciesComponent, TermsOfServiceComponent],
  imports: [CommonModule, ItemModule, CheckboxModule, FormsModule],
})
export class CompanyTextModule {}
