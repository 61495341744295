import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  /**Shows the modal */
  showModalInfo = false;

  /**Modal structure*/
  modalInfo = {
    title: '',
    msg: '',
    showIcon: false,
    iconName: '',
    iconColor: '',
    presentLoading: false,
    loadingColor: '',
    buttons: [],
  };

  /**Shows the modal or the navbar menu*/
  showModal = false;
  showMenu = true;

  /**
   *Method that opens the modal
   *@param modalInfo sets the modal info from outside
   */
  openModalInfo(modalInfo: any) {
    this.modalInfo = modalInfo;
    this.showModalInfo = true;
  }

  /**
   *hides the modal info
   */
  hideModalInfo() {
    this.showModalInfo = false;
  }
  /**
   *Method that opens the modal
   */
  openModal() {
    this.showModal = true;
  }

  /**
   *Method that opens the modal with a loading
   */
  showLoading(loadingColor: string, title: string, msg: string) {
    this.openModalInfo({
      title,
      msg,
      loadingColor,
      presentLoading: true,
    });
  }

  /**
   *Method that handles the success of a request with an alert
   */
  handleSuccess(title: string, msg: string, buttons?: any[]) {
    this.openModalInfo({
      presentLoading: false,
      showIcon: true,
      iconColor: 'success',
      iconName: 'bx bx-check-circle',
      title,
      msg,
      buttons: buttons || [
        {
          buttonColor: 'primary',
          buttonText: 'OK',
          buttonHandler: () => {
            this.hideModalInfo();
          },
        },
      ],
    });
  }

  /**
   *Method that handles error of a request with an alert
   */
  handleError(
    msg: string,
    title?: string | null,
    iconName?: string,
    iconColor?: string
  ) {
    this.openModalInfo({
      presentLoading: false,
      showIcon: true,
      iconColor: iconColor || 'warning',
      iconName: iconName || 'bx bxs-error-circle',
      title: title || 'Error',
      msg,
      buttons: [
        {
          buttonColor: 'primary',
          buttonText: 'OK',
          buttonHandler: () => {
            this.hideModalInfo();
          },
        },
      ],
    });
  }
}
