<div class="empty-message"
  [ngStyle]="{
  padding: padding,
  height: height
}">
  <figure class="background-logo" *ngIf="backgroundLogo">
    <img src="/assets/images/background-logo.png" />
  </figure>
  <div class="message-container">
    <figure>
      <img src="assets/images/CE-TV-logo.png" alt="Empty Message" width="100">
    </figure>
    <h4 class="text-align-center">{{ message }}</h4>
    <a [routerLink]="routerLink" class="text-primary font-weight-bold">
      {{ linkText }}
    </a>
  </div>
</div>
