<div class="checkbox-component" [ngStyle]="containerNgStyle">
  <input
    #checkbox
    [disabled]="disable"
    [readonly]="readonly"
    [id]="checkboxId"
    type="checkbox"
    (change)="toggleCheckbox()"
    [checked]="value" />
  <label [for]="checkboxId">
    <ng-content></ng-content>
  </label>
</div>
