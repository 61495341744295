import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchUrl',
})
export class MatchUrlPipe implements PipeTransform {
  transform(value: string, array: string[]): boolean {
    return array.some((valueArr) => value.includes(valueArr));
  }
}
