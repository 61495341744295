<footer class="footer" [ngClass]="{'auth-footer': authFooter}">
  <div class="links">
    <a class="pointer font-weight-bold" (click)="showLegalPolicies()">PRIVACY POLICY</a>
    <a class="pointer font-weight-bold" (click)="showTermsOfService()">TERMS & CONDITIONS</a>
  </div>

  <figure *ngIf="!authFooter">
    <img class="slogan" src="../../../../assets/images/be-everywhere-logo.png"
      alt="Be Everywhere All at Once" />
  </figure>
</footer>
