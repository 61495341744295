import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from './shared/components/alert/services/alert-controller.service';
import { PopUpController } from './shared/components/pop-up/services/pop-up-controller.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked {
  title = 'telematedAdNetwork';
  hideNavbar: boolean = false;

  urlsWithoutFooter = ['/ad-network', '/campaign/build-campaign', '/campaign/campaigns'];
  constructor(
    public router: Router,
    public popUpController: PopUpController,
    public alertController: AlertController,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterContentChecked(): void {
    this.hideNavbar = window.location.pathname === '/login' ||
      window.location.pathname === '/sign-up' ||
      window.location.pathname === '/forgot' ||
      window.location.pathname.split('/')[1] === 'campaigns';
    this.changeDetectorRef.detectChanges();
  }
}
