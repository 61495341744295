import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import { AlertLoadingComponent } from './components/alert-loading/alert-loading.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { ItemModule } from '../item/item.module';
import { ButtonModule } from '../button/button.module';
import { EmptyMessageComponent } from './components/empty-message/empty-message.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AlertComponent, AlertLoadingComponent, EmptyMessageComponent],
  imports: [CommonModule, SpinnerModule, ProgressBarModule, ItemModule, ButtonModule, RouterModule],
  exports: [AlertComponent, AlertLoadingComponent, EmptyMessageComponent],
})
export class AlertModule {}
