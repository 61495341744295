import {
  Component,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-dropdown-container',
  styleUrls: ['./dropdown-container.component.scss'],
  template: `
    <div (click)="toggle()" class="dropdown" [ngStyle]="{ padding: padding }">
      <ng-content></ng-content>
    </div>
  `,
})
export class DropdownContainerComponent {
  /**  Tells the parent component to open and close de dropdown  */
  @Output() closeDropdown = new EventEmitter();
  @Output() openDropdown = new EventEmitter();
  /**To open the dropdown from the parent component */
  @Input() isOpen = false;

  /**Changes the dropdown padding */
  @Input() padding = '';

  /**
   *
   * @param eRef element reference of this component
   */
  constructor(private eRef: ElementRef) {}

  /**
   *Checks when its clicked outside this component to close it
   */
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeDropdown.emit();
    }
  }

  /**
   *If its open the dropdown its close and if its close the dropdown its open
   */
  toggle() {
    if (this.isOpen) {
      this.closeDropdown.emit();
    } else {
      this.openDropdown.emit();
    }
  }
}
