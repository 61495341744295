import { AfterContentChecked, Component, Input } from '@angular/core';
import { PopUpController } from 'src/app/shared/components/pop-up/services/pop-up-controller.service';
import { LegalPoliciesComponent } from '../../company-text/legal-policies/legal-policies.component';
import { TermsOfServiceComponent } from '../../company-text/terms-of-service/terms-of-service.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterContentChecked {
  authFooter: boolean = false;

  constructor(private popUpController: PopUpController) {}

  ngAfterContentChecked(): void {
    this.authFooter = window.location.pathname === '/login' ||
      window.location.pathname === '/sign-up' ||
      window.location.pathname === '/forgot';
  }

  /**Shows terms of service */
  showTermsOfService() {
    this.popUpController.openPopUp({
      component: TermsOfServiceComponent,
      componentProps: {
        showCheckbox: false,
      },
      styles: {
        maxWidth: '500px',
        maxHeight: '500px',
      },
    });
  }
  /**Shows legal policies*/
  showLegalPolicies() {
    this.popUpController.openPopUp({
      component: LegalPoliciesComponent,
      styles: {
        maxWidth: '500px',
        maxHeight: '500px',
      },
    });
  }
}
