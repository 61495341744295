import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  /**
   *
   * @param router to manage the routing in the app
   */
  constructor(private router: Router) {}

  /**
   * Intercepts all the requests to add the token and handles the forbidden error
   * @param req request
   * @param next let pass the flow
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add the token

    let headers = new HttpHeaders();
    if (!req.url.includes('.mp4') && !req.url.includes('.jpg') && !req.url.includes('.png'))
      headers = new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      });

    //clones the request
    const reqClone = req.clone({
      headers,
    });

    // send the clone of the request
    return next.handle(reqClone).pipe(
      //handles the errors
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * Method that handles the forbidden error
   * @param req request
   * @param next let pass the flow
   */
  handleError(error: HttpErrorResponse) {
    if (error.status === 403) {
      error.error.detail = 'Session expired';
      // localStorage.removeItem('token');
      this.router.navigateByUrl('/auth');
    }
    if (!error.error.detail) {
      error.error.detail = error.statusText;
    }
    return throwError(() => error);
  }

  /**
   * get the token
   */
  get token() {
    return localStorage.getItem('token') || '';
  }
}
